import React from 'react'
import './style.css'
function Contact() {
  return (
    <>
    <div><h2 id='contact-anchor'>Contact</h2></div>
        {/* <div className='contact-grid'>
          <div className='contact-img-container'>
            <img src="images/webp/gals/basket.webp" alt="a wicker basket containing flowers and foliage" />
          </div>
            <div className='contact-ps'>
                <p>I find no matter the occasion, flowers are always a favourable option, never failing to bring joyfulness to all around. It is undoubtedly, one of my favourite things about being in this profession.</p> 
                <p>So, if you are in need of feel good flowers, inspired by nature's delights, please do get in touch.</p>
                
            </div>
        </div> */}

    </>
  )
}

export default Contact
