import React from 'react'
import './style.css'

function ParallaxPics() {
  return (
    <div className="banner-img">
        <div className='three'></div>
    </div> 
  )
}

export default ParallaxPics
